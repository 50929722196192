
import { collection, getDocs, addDoc, deleteDoc, doc, updateDoc } from "firebase/firestore";
import { onAuthStateChanged, signOut, signInWithPopup, GoogleAuthProvider, createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { db, auth } from '@/firebase';


export async function getAllDocs() {
  let data = {};
  var queryVideos = await getDocs(collection(db, 'video'));
  var queryGifs = await getDocs(collection(db, 'gifs'));

  let sortMapping = new Map(JSON.parse(localStorage.getItem('queueMap'))) || null;
  let videoData = _formateQueryData(queryVideos);

  if (sortMapping) {
    data.videos = _sortDataByqueueMap(videoData, sortMapping);}
  else data.videos = videoData;
  data.gifs = _formateQueryData(queryGifs);

  return data;
}

export async function setIsActive(collectionName, id, isActive) {
  console.log(collectionName, id, isActive);
  return await updateDoc(doc(db, collectionName, id), {
    isActive
  });
}

export async function updateRecord(collectionName, id, url, title) {
  console.log(collectionName, id, url, title);
  return await updateDoc(doc(db, collectionName, id), {
    url, title
  });
}

export async function addRecord(collectionName, url, title) {
  var query = await addDoc(collection(db, collectionName), {
    url,
    title,
    isActive: true
  });
  return (query.id);
}

export async function deleteRecord(collectionName, id) {
  return await deleteDoc(doc(collection(db, collectionName), id));
}

export function getCurrentUser() {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
}

export async function logout() {
  await signOut(auth)
}

export async function createNewUser(email, password) {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    if (user) {
      console.log("User created:", user);
      // Send email verification
      await sendEmailVerification(user);
      console.log("Verification email sent");
      // Additional actions upon successful user creation
    }

    return user;
  } catch (error) {
    console.error("Error creating user:", error);
    throw error;
  }
}


export async function createNewUserWithGoogle() {
  try {
    const provider = new GoogleAuthProvider();
    const result = await signInWithPopup(auth, provider);
    const { user } = result;

    if (user) {
      console.log("User created with Google:", user);
      // Additional actions upon successful user creation
    }

    return user;
  } catch (error) {
    console.error("Error creating user with Google:", error);
    throw error;
  }
}

function _formateQueryData(query) {
  var data = []
  query.forEach((doc) => {
    var obj = {
      id: doc.id,
      title: doc.data().title,
      url: doc.data().url,
      isActive: doc.data().isActive
    };
    data.push(obj);
  });
  return data;
}

function _sortDataByqueueMap(videos, sortingMap) {
  return videos.sort((a, b) => {
    const obj1Index = sortingMap.get(a.id);
    const obj2Index = sortingMap.get(b.id);

    if (obj1Index < obj2Index) return -1;
    if (obj1Index > obj2Index) return 1;
    return 0
  });
}