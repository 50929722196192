import { createRouter, createWebHashHistory } from 'vue-router'
import Player from '../components/music_player.vue';
import AdminPanel from '../components/admin_panel.vue';
import SignIn from '../components/sign_in.vue';
import { getCurrentUser } from "../firebase/firebase.js";

const routes = [
  {
    path: '/',
    name: 'home',
    component: Player
  },
  {
    path: '/login',
    name: 'login',
    component: SignIn
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminPanel,
    meta: {
      requiresAuth: true
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (requiresAuth && !await getCurrentUser()) {
    return '/login';
  } 
})

export default router
