/* eslint-disable vue/multi-word-component-names */
import * as Vue from 'vue';
// import axios from 'axios'
// import VueAxios from 'vue-axios'
import App from './App.vue';
import router from './router';
import YouTube from 'vue3-youtube'
import PrimeVue from 'primevue/config';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import DataView from 'primevue/dataview';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';     //optional for column grouping
import Row from 'primevue/row';                     //optional for row
import Message from 'primevue/message';
import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast';
import SpeedDial from 'primevue/speeddial';
import InputSwitch from 'primevue/inputswitch';
import Skycon from "vue-skycons";
import ConfirmationService from 'primevue/confirmationservice';
import ConfirmDialog from 'primevue/confirmdialog';
import Slider from 'primevue/slider';
import Tooltip from 'primevue/tooltip';
import Dialog from 'primevue/dialog';
import OverlayPanel from 'primevue/overlaypanel';
import Dropdown from 'primevue/dropdown';
import RadioButton from 'primevue/radiobutton';
import Dock from 'primevue/dock';

import 'primeflex/primeflex.css'

// import 'primevue/resources/themes/saga-blue/theme.css'
// import 'primevue/resources/themes/md-dark-indigo/theme.css'
// import 'primevue/resources/themes/lara-dark-blue/theme.css'
// import 'primevue/resources/themes/mdc-dark-indigo/theme.css'
// import 'primevue/resources/themes/nova-vue/theme.css'
// import 'primevue/resources/themes/fluent-light/theme.css'
// import 'primevue/resources/themes/luna-blue/theme.css'
// import 'primevue/resources/themes/tailwind-light/theme.css'
// import 'primevue/resources/themes/arya-blue/theme.css'
import 'primevue/resources/themes/bootstrap4-dark-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'

const app = Vue.createApp(App);
// app.use(VueAxios, axios)
app.use(PrimeVue);
app.component("Skycon", Skycon);
app.component('YouTube', YouTube)
app.component('InputText', InputText);
app.component('InputNumber', InputNumber);
app.component('Button', Button);
app.component('DataTable', DataTable);
app.component('DataView', DataView);
app.component('Column', Column);
app.component('ColumnGroup', ColumnGroup);
app.component('Row', Row);
app.component('Message', Message);
app.component('Toast', Toast);
app.component('SpeedDial', SpeedDial);
app.component('InputSwitch', InputSwitch);
app.component('ConfirmDialog', ConfirmDialog);
app.component('Slider', Slider);
app.component('Dialog', Dialog);
app.component('OverlayPanel', OverlayPanel);
app.component('Dropdown', Dropdown);
app.component('RadioButton', RadioButton);
app.component('Dock', Dock);
app.directive('Tooltip', Tooltip);
app.use(ConfirmationService);
app.use(ToastService);

app.use(router);
// app.use(babel)
app.mount('#app')
